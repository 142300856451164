$border: 1px solid #000;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
$col: rgb(255, 21, 21);
@mixin  mobile($size) {
  @media (max-width: $size) { @content; }
}
@mixin  fle($flex) {
  display: flex;
  flex-direction: $flex;
  justify-content: space-between;
  align-items: center;
}
* {
  font-family: 'Lato', sans-serif;
}

.flaw {
  // border: $border;
  width: 100%;
  .header {
    // border: $border;
    width: 95%;
    padding: 10px 0px;
    margin: auto;
    @include fle(row);
    .logo {
      // border: $border;
      width: 20%;
      height: 60px;
      @include mobile(1024px) {
        width: 30%;
      }
      @include mobile(768px) {
        width: 40%;
      }
      @include mobile(658px) {
        width: 50%;
      }
      @include mobile(545px) {
        width: 40%;
        background-size: 100%;
      }
    }
    .nv {
      // border: $border;
      width: 10%;
      display: none;
      @include mobile(769px) {
        display: block;
        cursor: pointer;
        width: 5%;
      }
      @include mobile(769px) {
        width: 6%;
      }
      @include mobile(450px) {
        width: 10%;
      }
      & div {
        margin-bottom: 5px;
      }
      .fi {
        border-top: 3px solid #000;
        width: 100%;
      }
      .se {
        border-top: 3px solid #000;
        width: 70%;
      }
      .seth {
        border-top: 3px solid #000;
        width: 40%;
      }
    }
    .nav {
      // border: $border;
      width: 60%;
      @include fle(row);
      @include mobile(1043px) {
        width: 70%;
      }
      @include mobile(850px) {
        width: 80%;
      }
      @include mobile(768px) {
        display: none;
      }
      & a {
        text-decoration: none;
        color: #000;
      }
      .active {
        border-bottom: 3px solid $col;
      }
    }
    .lit {
      display: none;
      width: 100%;
      background-color: rgba(0,0,0,0.4);
      position: fixed;
      top: 0px;
      right: 0px;
      height: 100%;
      z-index: 100;
      @include mobile(769px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .nav2 {
        width: 70%;
        background-color: #fff;
        height: 100%;
        position: fixed;
        top: 0px;
        right: 0px;
        .nbk {
          // border: $border;
          width: 100%;
          margin-top: 15%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .nmb {
            width: 90%;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            margin-bottom: 20px;
            & button {
              border: none;
              background-color: transparent;
            }
          }
          .ncw {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 35%;
            .woa {
              border: 1px solid #f4f5f9;
              width: 100%;
              padding: 15px 0px;
              & a {
                text-decoration: none;
                color: #000;
                font-size: 16px;
                // border: 1px solid #f00;
                margin-left: 10px;
              }
            }
            .woa2 {
              border: 1px solid #f4f5f9;
              width: 100%;
              // padding: 15px 0px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              .wo {
                border: 1px solid #f4f5f9;
                width: 20%;
                height: 45px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }
              & a {
                text-decoration: none;
                color: #000;
                font-size: 16px;
                // border: 1px solid #f00;
                margin-left: 10px;
              }
            }
            .woa3 {
              // border: 1px solid #f4f5f9;
              width: 100%;
              // padding: 15px 0px;
              display: flex;
              align-items: center;
              .wo {
                width: 15%;
                height: 45px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }
              & a {
                text-decoration: none;
                color: #000;
                font-size: 16px;
                // border: 1px solid #f00;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

section {
  width: 100%;
  .im {
    // border: $border;
    width: 100%;
    .number-slide {
      // border: $border;
      width: 100%;
      height: 100%;
    }
  }
  .cont {
    // border: $border;
    width: 80%;
    margin: 50px auto;
    @include fle(row);
    color: #999;
    @include mobile(768px) {
      @include fle(column);
    }
    .ch {
      font-size: 40px;
      font-weight: 700;
      color: #000;
    }
    & a {
      text-decoration: none;
      color: rgb(0, 140, 255);
    }
    .ch2 {
      margin-top: 20px;
      // font-size: 20px;
      font-weight: 500;
      color: #000;
      // margin-bottom: 20px;
    }
    .hgbv {
      // border: $border;
      width: 50%;
      height: 500px;
      @include mobile(768px) {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .ag {
    // border: $border;
    width: 90%;
    margin: 40px auto;
    .lkvc {
      width: 60%;
      background-color: #149754; 
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px; 
      font-size: 18px;
      margin-top: 10px;
      font-weight: 600;
      color: #fff;
      @include mobile(768px) {
        width: 95%;
      }
    }
    .sipl {
      width: 20%;
      margin-top: 20px;
      @include mobile(768px) {
        width: 90%
      }
    }
    .cb {
      width: 100%;
      text-align: center;
    }
    .map {
      // border: $border;
      width: 100%;
      height: 300px;
      margin-top: 10px;
    }
    .cc {
      width: 100%;
      // text-align: center;
    }
    .tt {
      // border: $border;
      width: 100%;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include mobile(768px) {
        flex-direction: column;
      }
      .ant {
        // border: $border;
        width: 20%;
        height:200px;
        @include mobile(768px) {
          width: 100%;
          height: auto;
        }
      }
      .ant2 {
        width: 79%;
        @include mobile(768px) {
          width: 100%;
          margin-top: 10px;
        }
        .na {
          font-size: 25px;
          font-weight: 700;
          padding: 5px 0px;
        }
      }
      .antio {
        width: 100%;
        @include mobile(768px) {
          width: 100%;
          margin-top: 10px;
        }
        .na {
          font-size: 25px;
          font-weight: 700;
          padding: 5px 0px;
        }
      }
    }
    .h1 {
      font-size: 40px;
      font-weight: 700;
    }
    .h2 {
      font-size: 20px;
      font-weight: 700;
    }
    .com {
      margin: 10px 0px;
      // color: #999;
      line-height: 30px;
      letter-spacing: 1px;
    }
    & a {
      color: #149754;
    }
  }
  .vc {
    width: 90%;
    // border: $border;
    margin: auto;
    @include fle(row);
    height: 650px;
    @include mobile(1080px) {
      display: none;
    }
    position: relative;
    .one {
      // border: $border;
      width: 47%;
      height: 100%;
      @include fle(row);
      position: relative;
      .ima {
        // border: $border;
        width: 40%;
        height: 80%;
        border-radius: 5px;
        background-image: url(./assets/f1.png);
        background-size: cover;
        background-position: right;
      }
      .sd {
        // border: $border;
        width: 70%;
        height: 100%;
        position: absolute;
        right: 0px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .kl {
          // border: $border;
          width: 85%;
          height: 80%;
          color: #999;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          & div {
            width: 100%;
            line-height: 30px;
            font-style: italic;
            font-size: 18px;
          }
        }
        .jh {
          width: 85%;
          .xc2 {
            color: #999;
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }
    .two {
      // border: $border;
      width: 47%;
      height: 100%;
      @include fle(row);
      position: relative;
      .ima {
        // border: $border;
        width: 40%;
        height: 80%;
        border-radius: 5px;
        background-image: url(./assets/f2.jpeg);
        background-size: cover;
        background-position: center;
        position: absolute;
        right: 0px;
        z-index: -1;
      }
      .sd {
        // border: $border;
        width: 70%;
        height: 100%;
        background-color: #fff;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .kl {
          width: 85%;
          height: 80%;
          color: #999;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          & div {
            width: 100%;
            line-height: 30px;
            font-style: italic;
            font-size: 18px;
          }
        }
        .jh {
          width: 85%;
          .xc2 {
            color: #999;
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .vc2 {
    width: 90%;
    margin: 20px auto;
    display: none;
    @include mobile(1080px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .one {
      // border: $border;
      width: 100%;
      .ima {
        // border: $border;
        width: 100%;
        height: 300px;
        border-radius: 5px;
        background-image: url(./assets/f1.png);
        background-size: cover;
        background-position: center;
      }
      .sd {
        width: 100%;
        margin: 10px 0px;
        .kl {
          width: 100%;
          line-height: 22px;
          // font-style: italic;
          font-size: 16px;
          color: #555;
        }
        .jh {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          .xc2 {
            color: #999;
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }
    .two {
      // border: $border;
      width: 100%;
      margin-top: 10px;
      .ima {
        // border: $border;
        width: 100%;
        height: 300px;
        border-radius: 5px;
        background-image: url(./assets/f2.jpeg);
        background-size: cover;
        background-position: center;
      }
      .sd {
        width: 100%;
        margin: 10px 0px;
        .kl {
          width: 100%;
          line-height: 22px;
          // font-style: italic;
          font-size: 16px;
          color: #555;
        }
        .jh {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          .xc2 {
            color: #999;
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .av {
    // border: $border;
    width: 100%;
    margin: 10px 0px;
    height: 800px;
    background-image: url(./assets/f4.jpg);
    background-size: cover;
    background-position: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mobile(1080px) {
      height: 600px;
    }
    .mi {
      // border: $border;
      width: 90%;
      height: 90%;
      background-color: rgba(0,0,0,0.4);
      @include fle(row);
      @include mobile(1080px) {
        flex-direction: column;
        justify-content: flex-start;
      }
      .qw {
        margin-left: 30px;
        @include mobile(1080px) {
          width: 100%;
          margin-top: 20px;
        }
        .qw1 {
          font-size: 70px;
          font-weight: 700;
          @include mobile(1080px) {
            font-size: 40px;
          }
        }
        .qw2 {
          font-size: 40px;
          @include mobile(1080px) {
            font-size: 20px;
          }
        }
      }
      .qt {
        width: 50%;
        // height: 80%;
        @include mobile(1080px) {
          width: 90%;
          margin-top: 20px;
        }
        .qt1 {
          font-size: 70px;
          // font-weight: 700;
          @include mobile(1080px) {
            font-size: 40px;
          }
        }
        .qt2 {
          width: 30%;
          margin: 20px 0px 0px 0px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @include mobile(1080px) {
            width: 100%;
          }
          & a {
            text-decoration: none;
            color: #fff;
            margin-bottom: 20px;
            // font-size: 20px;
          }
        }
        .qt2 a:hover{
          border-bottom: 3px solid #fff;
        }
        .eer {
          margin-top: 50px;
        }
        .nim {
          color: #fff;
          text-decoration: none;
          background-color: $col;
          padding: 10px 20px;
          border-radius: 5px;
        }
      }
    }
  }
  .kiw {
    // border: $border;
    width: 90%;
    margin: 30px auto;
    .hh {
      font-size: 30px;
      font-weight: 700;
      width: 100%;
      text-align: center;
    }
    .pp {
      width: 100%;
      margin: 10px 0px;
      @include fle(row);
      flex-wrap: wrap;
      .p1 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/1.jpg);
        background-size: 100%;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p2 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/2.jpg);
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p3 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/3.jpg);
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p4 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/1.png);
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p5 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/2.png);
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p6 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/3.png);
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p7 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/4.png);
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p8 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/5.png);
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p9 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/6.png);
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p10 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/7.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p11 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/8.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p12 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/9.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
      .p13 {
        width: 22%;
        height: 120px;
        margin: 10px 0px;
        background-image: url(./assets/10.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        @include mobile(768px) {
          width: 49%;
        }
      }
    }
  }
  .sip {
    // border: $border;
    width: 100%;
    .rec2 {
      // border: $border;
      width: 60%;
      position: absolute;
      bottom: 0px;
      right: 0px;
        // background-color: rgb(250, 107, 255);
        background-color: #000;
      color: #fff;
      font-size: 30px;
      padding: 10px 0px;
      @include mobile(768px) {
        width: 100%;
        font-size: 25px;
      }
      & div {
        margin-left: 10px;
      }
    }

  }
  .sip3 {
    // border: $border;
    width: 100%;
    height: 300px;
    background-image: url(./assets/to3.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    @include mobile(768px) {
      height: 200px;
    }
    .rec4 {
      // border: $border;
      width: 60%;
      position: absolute;
      bottom: 0px;
      right: 0px;
        background-color: rgb(250, 107, 255);
        // background-color: #000;
      color: #fff;
      font-size: 30px;
      padding: 10px 0px;
      @include mobile(768px) {
        width: 100%;
        font-size: 25px;
      }
      & div {
        margin-left: 10px;
      }
    }
    .rec5 {
      // border: $border;
      width: 60%;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background-color: rgb(45, 136, 255);
      color: #fff;
      font-size: 30px;
      padding: 10px 0px;
      @include mobile(768px) {
        width: 100%;
        font-size: 25px;
      }
    }
  }
  .siper {
    // border: $border;
    width: 70%;
    margin-top: 30px;
    .rec3 {
      // border: $border;
      width: 60%;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background-color: rgb(195, 0, 255);
      color: #fff;
      font-size: 30px;
      padding: 10px 0px;
      @include mobile(768px) {
        width: 100%;
        font-size: 25px;
      }
      & div {
        margin-left: 10px;
      }
    }
  }
  .sip2 {
    // border: $border;
    width: 100%;
    .rec3 {
      // border: $border;
      width: 60%;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background-color: rgb(195, 0, 255);
      color: #fff;
      font-size: 30px;
      padding: 10px 0px;
      @include mobile(768px) {
        width: 100%;
        font-size: 25px;
      }
      & div {
        margin-left: 10px;
      }
    }
  }
  .sii {
    // border: $border;
    width: 100%;
    height: 300px;
    background-image: url(./assets/cb.jpg);
    background-size: cover;
    background-position: bottom;
    position: relative;
    @include mobile(768px) {
      height: 200px;
      background-position: center;
    }
  }
  .sia {
    // border: $border;
    width: 100%;
    // height: 300px;
    position: relative;
    .rec {
      // border: $border;
      width: 60%;
      position: absolute;
      bottom: 0px;
      right: 0px;
        background-color: #f00;
      color: #fff;
      font-size: 30px;
      padding: 10px 0px;
      @include mobile(768px) {
        width: 100%;
        font-size: 25px;
      }
      & div {
        margin-left: 10px;
      }
    }
    .rec2 {
      // border: $border;
      width: 60%;
      position: absolute;
      bottom: 0px;
      right: 0px;
        background-color: rgb(250, 107, 255);
      color: #fff;
      font-size: 30px;
      padding: 10px 0px;
      @include mobile(768px) {
        width: 100%;
        font-size: 25px;
      }
      & div {
        margin-left: 10px;
      }
    }
    .rec4 {
      // border: $border;
      width: 60%;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background-color: rgb(138, 83, 0);
      color: #fff;
      font-size: 30px;
      padding: 10px 0px;
      @include mobile(768px) {
        width: 100%;
        font-size: 25px;
      }
      & div {
        margin-left: 10px;
      }
    }
    .rec5 {
      // border: $border;
      width: 60%;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background-color: rgb(45, 136, 255);
      color: #fff;
      font-size: 30px;
      padding: 10px 0px;
      @include mobile(768px) {
        width: 100%;
        font-size: 25px;
      }
      & div {
        margin-left: 10px;
      }
    }
  }
  .asey {
    // border: $border;
    width: 100%;
    @include fle(row);
    border-radius: 5px;
    .ase1 {
      border-bottom: 1px solid #fff;
      border-right: 5px solid #fff;
      background-color: rgb(250, 107, 255);
    }
    .ase2 {
      background-color: #000;
    }
    .ase3 {
      border-bottom: 1px solid #fff;
      border-right: 5px solid #fff;
      background-color: #000;
    }
    .ase4 {
      background-color: rgb(45, 136, 255);
    }
    & div {
      width: 50%;
      text-align: center;
      padding: 20px 0px;
      & a {
        text-decoration: none;
        color: #fff;
        font-weight: 700;
      }
    }
  }
  .sia2 {
    // border: $border;
    width: 100%;
    .rec {
      // border: $border;
      width: 60%;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background-color: rgb(255, 119, 56);
      color: #fff;
      font-size: 30px;
      padding: 10px 0px;
      @include mobile(768px) {
        width: 100%;
        font-size: 25px;
      }
      & div {
        margin-left: 10px;
      }
    }
  }
}

.foot {
  width: 100%;
  padding: 20px 0px;
  background-color: #f00;
  .footer {
    width: 90%;
    margin: auto;
    text-align: center;
    color: #fff;
    .sbl {
      & a {
        margin-left: 10px;
      }
    }
    & div {
      margin: 10px 0px 0px 0px;
      & a  {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
.foot2 {
  width: 100%;
  padding: 20px 0px;
  background-color: #000;
  .footer {
    width: 90%;
    margin: auto;
    text-align: center;
    color: #fff;
    .sbl {
      & a {
        margin-left: 10px;
      }
    }
    & div {
      margin: 10px 0px 0px 0px;
      & a  {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.allc {
  // border: $border;
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  .gb {
    font-family: 'Playfair Display', serif;
    color: #fff;
    font-size: 30px;
    margin-top: 30px;
  }
  .gb2 {
    width: 3%;
    background-color: #fff;
    padding: 1.5px 0px;
    margin: 10px 0px 30px 0px;
    @include mobile(1024px) {
      width: 10%;
    }
  }
  .gb3 {
    // border: 1px solid #fff;
    width: 70%;
    margin: 10px 0px 30px 0px;
    @include fle(row);
    @include mobile(1024px) {
      @include fle(column);
      width: 90%;
    }
    .in1 {
      // border: 1px solid #fff;
      width: 30%;
      height: 200px;
      background-color: #222;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include mobile(1024px) {
        width: 100%;
        margin-bottom: 10px;
      }
      .st {
        font-size: 18px;
        margin: 20px 0px;
        font-weight: 700;
      }
      & a {
        text-decoration: none;
        color: #fff;
      }
    }
    .in2 {
      // border: 1px solid #fff;
      width: 35%;
      height: 200px;
      background-color: #222;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include mobile(1024px) {
        width: 100%;
        margin-bottom: 10px;
      }
      .st {
        font-size: 18px;
        margin: 20px 0px;
        font-weight: 700;
      }
      & a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
  .gb4 {
    border: 1px solid #fff;
    width: 10%;
    text-align: center;
    padding: 10px 0px;
    margin-bottom: 60px;
    @include mobile(1024px) {
      width: 90%;
    }
    & a {
      text-decoration: none;
      color: #fff;
      font-weight: 700;
    }
  }
}

  
.ser {
  // border: $border;
  // width: 15%;
  position: relative;
  .serv {
    // border: $border;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .active3 .active2 {
    border-bottom: 3px solid $col;
  }
  .serv2 {
    width: 250%;
    // border: $border;
    position: absolute;
    top: 100%;
    z-index: 100;
    background-color: #fff;
    display: none;
    .ari {
      // border: $border;
      width: 90%;
      display: flex;
      flex-direction: column;
      margin: 10px auto;
      & a {
        padding: 5px 3px;
        &:hover {
          background-color: $col;
          color: #fff;
        }
      }
      .active3 {
        background-color: $col;
        color: #fff;
      }
    }
  }
}
.ser:hover > .serv2 {
  display: block;
}

.ser2 {
  // border: $border;
  // width: 15%;
  position: relative;
  .serv3 {
    // border: $border;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .active3 .active2 {
    border-bottom: 3px solid $col;
  }
  .serv4 {
    width: 350%;
    // border: $border;
    position: absolute;
    top: 100%;
    z-index: 100;
    background-color: #fff;
    display: none;
    .ari2 {
      // border: $border;
      width: 90%;
      display: flex;
      flex-direction: column;
      margin: 10px auto;
      & a {
        padding: 5px 3px;
        &:hover {
          background-color: $col;
          color: #fff;
        }
      }
      .active3 {
        background-color: $col;
        color: #fff;
      }
    }
  }
}
.ser2:hover > .serv4 {
  display: block;
}